// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function formatShowingTimes(startDate, endDate) {
  return startDate.toLocaleString("en-us", {
              weekday: "short",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            }) + " - " + endDate.toLocaleString("en-us", {
              hour: "numeric",
              minute: "numeric"
            });
}

exports.formatShowingTimes = formatShowingTimes;
/* No side effect */
