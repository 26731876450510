// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Tooltip = require("../../../reason/common/Tooltip.bs.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CurrentUser = require("../../../reason/common/CurrentUser/CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ShowingsUtils = require("../../../v5/common/ShowingsUtils.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var NotificationWidgetTexts_showing_graphql = require("../../../__generated__/NotificationWidgetTexts_showing_graphql.bs.js");
var NotificationWidgetTextsAgent_agent_graphql = require("../../../__generated__/NotificationWidgetTextsAgent_agent_graphql.bs.js");
var NotificationWidgetTextsAgent_agentId_graphql = require("../../../__generated__/NotificationWidgetTextsAgent_agentId_graphql.bs.js");
var NotificationWidgetTextsBuyerNeed_aml_graphql = require("../../../__generated__/NotificationWidgetTextsBuyerNeed_aml_graphql.bs.js");
var NotificationWidgetTextsForSale_agent_graphql = require("../../../__generated__/NotificationWidgetTextsForSale_agent_graphql.bs.js");
var NotificationWidgetTextsRentalNeed_aml_graphql = require("../../../__generated__/NotificationWidgetTextsRentalNeed_aml_graphql.bs.js");
var NotificationWidgetTextsForSale_agentId_graphql = require("../../../__generated__/NotificationWidgetTextsForSale_agentId_graphql.bs.js");
var NotificationWidgetTextsClientLabel_client_graphql = require("../../../__generated__/NotificationWidgetTextsClientLabel_client_graphql.bs.js");
var NotificationWidgetTextsRentalProperty_aml_graphql = require("../../../__generated__/NotificationWidgetTextsRentalProperty_aml_graphql.bs.js");
var NotificationWidgetTextsForSaleProperty_aml_graphql = require("../../../__generated__/NotificationWidgetTextsForSaleProperty_aml_graphql.bs.js");
var NotificationWidgetTextsPropertyLocationLabel_property_graphql = require("../../../__generated__/NotificationWidgetTextsPropertyLocationLabel_property_graphql.bs.js");

var convertFragment = NotificationWidgetTextsPropertyLocationLabel_property_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsPropertyLocationLabel_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsPropertyLocationLabel_property_graphql.node, convertFragment);
}

function readInline(fRef) {
  return RescriptRelay_Fragment.readInlineData(NotificationWidgetTextsPropertyLocationLabel_property_graphql.node, convertFragment, fRef);
}

var LocationFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readInline: readInline
};

function getLabel($$location) {
  var $$location$1 = RescriptRelay_Fragment.readInlineData(NotificationWidgetTextsPropertyLocationLabel_property_graphql.node, convertFragment, $$location);
  var match = $$location$1.propertyLocation;
  if (match === undefined) {
    return "-";
  }
  if (typeof match !== "object") {
    return "-";
  }
  var variant = match.NAME;
  if (variant === "PropertyLocationWithHiddenAddress") {
    var locationDisplay = match.VAL.locationDisplay;
    if (locationDisplay !== undefined) {
      return locationDisplay;
    } else {
      return "-";
    }
  }
  if (variant !== "PropertyLocationWithVisibleAddress") {
    return "-";
  }
  var locationDisplay$1 = match.VAL.address;
  if (locationDisplay$1 !== undefined) {
    return locationDisplay$1;
  } else {
    return "-";
  }
}

var PropertyLocationLabel = {
  LocationFragment: LocationFragment,
  getLabel: getLabel
};

var convertFragment$1 = NotificationWidgetTextsClientLabel_client_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsClientLabel_client_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsClientLabel_client_graphql.node, convertFragment$1);
}

function readInline$1(fRef) {
  return RescriptRelay_Fragment.readInlineData(NotificationWidgetTextsClientLabel_client_graphql.node, convertFragment$1, fRef);
}

var ClientFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readInline: readInline$1
};

function getLabel$1(client) {
  var client$1 = RescriptRelay_Fragment.readInlineData(NotificationWidgetTextsClientLabel_client_graphql.node, convertFragment$1, client);
  var contacts = Core__Array.keepSome(Core__Option.getOr(Core__Option.flatMap(client$1.contacts, (function (c) {
                    return c.edges;
                  })), [])).map(function (c) {
        return c.node.displayName;
      });
  if (contacts.length !== 0) {
    return contacts.join(", ");
  }
  
}

var ClientLabel = {
  ClientFragment: ClientFragment,
  getLabel: getLabel$1
};

var convertFragment$2 = NotificationWidgetTextsAgent_agent_graphql.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsAgent_agent_graphql.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsAgent_agent_graphql.node, convertFragment$2);
}

var Agent = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$2,
  useOpt: useOpt$2
};

function useAgentTextOpt(fragmentRefs) {
  var currentUser = CurrentUser.useCurrentUser();
  var data = useOpt$2(fragmentRefs);
  if (data === undefined) {
    return "A TAN agent";
  }
  var displayName = data.displayName;
  var match = currentUser.user;
  if (match !== undefined && match.id === data.id) {
    return "You";
  } else {
    return displayName;
  }
}

function useAgentText(fragmentRefs) {
  return useAgentTextOpt(Caml_option.some(fragmentRefs));
}

var convertFragment$3 = NotificationWidgetTextsForSale_agent_graphql.Internal.convertFragment;

function use$3(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSale_agent_graphql.node, convertFragment$3, fRef);
}

function useOpt$3(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsForSale_agent_graphql.node, convertFragment$3);
}

var ForSaleAgent = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$3,
  use: use$3,
  useOpt: useOpt$3
};

function useForSaleAgentText(fragmentRefs) {
  var data = RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSale_agent_graphql.node, convertFragment$3, fragmentRefs);
  var fragmentRef;
  if (typeof data === "object" && data.NAME === "VisibleForSalePropertyAgent") {
    var match = data.VAL.agent;
    fragmentRef = match !== undefined ? Caml_option.some(match.fragmentRefs) : undefined;
  } else {
    fragmentRef = undefined;
  }
  var text = useAgentTextOpt(fragmentRef);
  if (typeof data === "object" && data.NAME === "AnonymousForSalePropertyAgent") {
    return "A TAN agent";
  } else {
    return text;
  }
}

var AgentText = {
  Agent: Agent,
  useAgentTextOpt: useAgentTextOpt,
  useAgentText: useAgentText,
  ForSaleAgent: ForSaleAgent,
  useForSaleAgentText: useForSaleAgentText
};

var convertFragment$4 = NotificationWidgetTextsAgent_agentId_graphql.Internal.convertFragment;

function use$4(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4, fRef);
}

function useOpt$4(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4);
}

var Agent$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$4,
  use: use$4,
  useOpt: useOpt$4
};

function useAgentId(fragmentRefs) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4, fragmentRefs).id;
}

var convertFragment$5 = NotificationWidgetTextsForSale_agentId_graphql.Internal.convertFragment;

function use$5(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSale_agentId_graphql.node, convertFragment$5, fRef);
}

function useOpt$5(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsForSale_agentId_graphql.node, convertFragment$5);
}

var ForSaleAgent$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$5,
  use: use$5,
  useOpt: useOpt$5
};

function useForSaleAgentId(fragmentRefs) {
  var data = RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSale_agentId_graphql.node, convertFragment$5, fragmentRefs);
  if (typeof data !== "object") {
    return ;
  }
  if (data.NAME !== "VisibleForSalePropertyAgent") {
    return ;
  }
  var match = data.VAL.agent;
  if (match !== undefined) {
    return match.id;
  }
  
}

var AgentId = {
  Agent: Agent$1,
  useAgentId: useAgentId,
  ForSaleAgent: ForSaleAgent$1,
  useForSaleAgentId: useForSaleAgentId
};

var convertFragment$6 = NotificationWidgetTexts_showing_graphql.Internal.convertFragment;

function use$6(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTexts_showing_graphql.node, convertFragment$6, fRef);
}

function useOpt$6(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTexts_showing_graphql.node, convertFragment$6);
}

var ShowingFragment_visibilityReason_decode = NotificationWidgetTexts_showing_graphql.Utils.visibilityReason_decode;

var ShowingFragment_visibilityReason_fromString = NotificationWidgetTexts_showing_graphql.Utils.visibilityReason_fromString;

var ShowingFragment = {
  visibilityReason_decode: ShowingFragment_visibilityReason_decode,
  visibilityReason_fromString: ShowingFragment_visibilityReason_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$6,
  use: use$6,
  useOpt: useOpt$6
};

function NotificationWidgetTexts$ShowingNotification(props) {
  var showing = RescriptRelay_Fragment.useFragment(NotificationWidgetTexts_showing_graphql.node, convertFragment$6, props.showing);
  var agentId = useForSaleAgentId(showing.property.agentV2.fragmentRefs);
  var agentText = useForSaleAgentText(showing.property.agentV2.fragmentRefs);
  var verb = agentText === "You" ? "are" : "is";
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var $$location = getLabel(showing.property.fragmentRefs);
  var duration = ShowingsUtils.formatShowingTimes(showing.startDateV2, showing.endDateV2);
  var match = showing.property.propertyLocation;
  var match$1;
  if (match !== undefined) {
    var variant = match.NAME;
    if (variant === "PropertyLocationWithVisibleAddress") {
      var match$2 = match.VAL;
      match$1 = [
        match$2.address,
        match$2.locationDisplay,
        match$2.visibilityRule
      ];
    } else {
      match$1 = variant === "UnselectedUnionMember" ? [
          undefined,
          "-",
          undefined
        ] : [
          undefined,
          match.VAL.locationDisplay,
          undefined
        ];
    }
  } else {
    match$1 = [
      undefined,
      "-",
      undefined
    ];
  }
  var visibilityRule = match$1[2];
  var locationDisplay = match$1[1];
  var address = match$1[0];
  var tmp;
  if (shouldShowPropertyDetails) {
    var tmp$1;
    if (address !== undefined) {
      var exit = 0;
      if (visibilityRule !== undefined && (visibilityRule === "VISIBLE_TO_TAN_STAFF" || visibilityRule === "VISIBLE_TO_ACCOUNT_MEMBER")) {
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("span", {
                      children: locationDisplay
                    }),
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Icon__Jsx3.make, {
                                    icon: "Lock",
                                    className: "text-gray-600 mr-1",
                                    size: 16
                                  }),
                              JsxRuntime.jsx(Tooltip.make, {
                                    label: address + (
                                      visibilityRule === "VISIBLE_TO_TAN_STAFF" ? " (visible to TAN staff)" : " (only visible to you)"
                                    ),
                                    children: JsxRuntime.jsx("div", {
                                          children: "Hidden Address",
                                          className: "border-dotted border-b border-gray-400"
                                        })
                                  })
                            ],
                            className: "inline-flex flex-row items-center lg:before:content-['('] lg:after:content-[')'] before:content-none after:content-none"
                          }),
                      className: ""
                    })
              ]
            });
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp$1 = JsxRuntime.jsx("span", {
              children: address + " in " + locationDisplay
            });
      }
      
    } else {
      tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx("span", {
                    children: locationDisplay
                  }),
              JsxRuntime.jsx("span", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(Icon__Jsx3.make, {
                                  icon: "Lock",
                                  className: "text-gray-600 mr-1",
                                  size: 16
                                }),
                            JsxRuntime.jsx("div", {
                                  children: "Contact agent for address"
                                })
                          ],
                          className: "ml-1 inline-flex flex-row items-center lg:before:content-['('] lg:after:content-[')'] before:content-none after:content-none"
                        }),
                    className: ""
                  })
            ]
          });
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            agentText + " " + verb + " showing ",
            JsxRuntime.jsx("span", {
                  children: tmp$1,
                  className: ""
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {
          children: Caml_option.some("A TAN agent is showing " + $$location)
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                JsxRuntime.jsx("br", {}),
                duration
              ]
            });
}

var ShowingNotification = {
  ShowingFragment: ShowingFragment,
  make: NotificationWidgetTexts$ShowingNotification
};

var convertFragment$7 = NotificationWidgetTextsForSaleProperty_aml_graphql.Internal.convertFragment;

function use$7(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSaleProperty_aml_graphql.node, convertFragment$7, fRef);
}

function useOpt$7(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsForSaleProperty_aml_graphql.node, convertFragment$7);
}

var AmlFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$7,
  use: use$7,
  useOpt: useOpt$7
};

function NotificationWidgetTexts$PropertyMatchText(props) {
  var aml = RescriptRelay_Fragment.useFragment(NotificationWidgetTextsForSaleProperty_aml_graphql.node, convertFragment$7, props.aml);
  var agentId = useForSaleAgentId(aml.source.agentV2.fragmentRefs);
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var agentText = useForSaleAgentText(aml.source.agentV2.fragmentRefs);
  var match = aml.event;
  var verb;
  if (match !== undefined) {
    var variant = match.NAME;
    verb = variant === "QuietPropertyCreatedAMLEvent" || variant === "BroadcastPropertyCreatedAMLEvent" ? "added" : (
        variant === "UnselectedUnionMember" ? "-" : "updated"
      );
  } else {
    verb = "added";
  }
  var match$1 = aml.event;
  var matches;
  if (match$1 !== undefined) {
    if (typeof match$1 === "object") {
      var variant$1 = match$1.NAME;
      if (variant$1 === "ForSalePropertyEditedAMLEvent") {
        var match$2 = match$1.VAL;
        var match$3 = match$2.existingMatches;
        if (match$3 !== undefined) {
          var match$4 = match$2.newMatches;
          matches = match$4 !== undefined ? ({
                TAG: "ExistingAndNew",
                new: Core__Array.keepSome(match$4.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    }),
                existing: Core__Array.keepSome(match$3.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    })
              }) : ({
                TAG: "Matches",
                _0: []
              });
        } else {
          matches = {
            TAG: "Matches",
            _0: []
          };
        }
      } else if (variant$1 === "QuietPropertyBroadcastedAMLEvent") {
        var match$5 = match$1.VAL;
        var match$6 = match$5.existingMatches;
        if (match$6 !== undefined) {
          var match$7 = match$5.newMatches;
          matches = match$7 !== undefined ? ({
                TAG: "ExistingAndNew",
                new: Core__Array.keepSome(match$7.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    }),
                existing: Core__Array.keepSome(match$6.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    })
              }) : ({
                TAG: "Matches",
                _0: []
              });
        } else {
          matches = {
            TAG: "Matches",
            _0: []
          };
        }
      } else if (variant$1 === "ForSalePropertyRebroadcastedAMLEvent") {
        var match$8 = match$1.VAL;
        var match$9 = match$8.existingMatches;
        if (match$9 !== undefined) {
          var match$10 = match$8.newMatches;
          matches = match$10 !== undefined ? ({
                TAG: "ExistingAndNew",
                new: Core__Array.keepSome(match$10.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    }),
                existing: Core__Array.keepSome(match$9.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    })
              }) : ({
                TAG: "Matches",
                _0: []
              });
        } else {
          matches = {
            TAG: "Matches",
            _0: []
          };
        }
      } else if (variant$1 === "BroadcastPropertyCreatedAMLEvent") {
        var match$11 = match$1.VAL.matches;
        matches = match$11 !== undefined ? ({
              TAG: "Matches",
              _0: Core__Array.keepSome(match$11.edges).map(function (e) {
                    return e.node.source.client.fragmentRefs;
                  })
            }) : ({
              TAG: "Matches",
              _0: []
            });
      } else if (variant$1 === "QuietPropertyCreatedAMLEvent") {
        var match$12 = match$1.VAL.matches;
        matches = match$12 !== undefined ? ({
              TAG: "Matches",
              _0: Core__Array.keepSome(match$12.edges).map(function (e) {
                    return e.node.source.client.fragmentRefs;
                  })
            }) : ({
              TAG: "Matches",
              _0: []
            });
      } else {
        matches = {
          TAG: "Matches",
          _0: []
        };
      }
    } else {
      matches = {
        TAG: "Matches",
        _0: []
      };
    }
  } else {
    matches = {
      TAG: "Matches",
      _0: []
    };
  }
  var matchesText;
  var exit = 0;
  var client;
  var matches$1;
  if (matches.TAG === "Matches") {
    var matches$2 = matches._0;
    if (matches$2.length !== 1) {
      matches$1 = matches$2;
      exit = 2;
    } else {
      var client$1 = matches$2[0];
      client = client$1;
      exit = 1;
    }
  } else {
    var matches$3 = matches.new;
    var exit$1 = 0;
    var len = matches$3.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var matches$4 = matches.existing;
        if (matches$4.length !== 1) {
          matches$1 = matches$4;
          exit = 2;
        } else {
          var client$2 = matches$4[0];
          client = client$2;
          exit = 1;
        }
      }
    } else {
      var client$3 = matches$3[0];
      if (matches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = client$3;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (matches.existing.length !== 0) {
        var length = matches$3.length;
        matchesText = length.toString() + " more of your buyers";
      } else {
        matches$1 = matches$3;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        var clientName = Core__Option.getOr(getLabel$1(client), "client name");
        matchesText = "your buyer " + clientName;
        break;
    case 2 :
        var length$1 = matches$1.length;
        matchesText = length$1.toString() + " of your buyers";
        break;
    
  }
  if (shouldShowPropertyDetails) {
    return agentText + " " + verb + " a property that matches " + matchesText;
  } else {
    return "A TAN agent " + verb + " a property that matches " + matchesText;
  }
}

var PropertyMatchText = {
  AmlFragment: AmlFragment,
  make: NotificationWidgetTexts$PropertyMatchText
};

var convertFragment$8 = NotificationWidgetTextsRentalProperty_aml_graphql.Internal.convertFragment;

function use$8(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsRentalProperty_aml_graphql.node, convertFragment$8, fRef);
}

function useOpt$8(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsRentalProperty_aml_graphql.node, convertFragment$8);
}

var AmlFragment$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$8,
  use: use$8,
  useOpt: useOpt$8
};

function NotificationWidgetTexts$RentalPropertyMatchText(props) {
  var aml = RescriptRelay_Fragment.useFragment(NotificationWidgetTextsRentalProperty_aml_graphql.node, convertFragment$8, props.aml);
  var agentId = RescriptRelay_Fragment.useFragment(NotificationWidgetTextsAgent_agentId_graphql.node, convertFragment$4, aml.source.agent.fragmentRefs).id;
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var agentText = useAgentTextOpt(Caml_option.some(aml.source.agent.fragmentRefs));
  var match = aml.event;
  var verb;
  if (match !== undefined) {
    var variant = match.NAME;
    verb = variant === "RentalPropertyCreatedAMLEvent" ? "added" : (
        variant === "UnselectedUnionMember" ? "-" : "updated"
      );
  } else {
    verb = "added";
  }
  var match$1 = aml.event;
  var mtches;
  if (match$1 !== undefined) {
    if (typeof match$1 === "object") {
      var variant$1 = match$1.NAME;
      if (variant$1 === "RentalPropertyEditedAMLEvent") {
        var match$2 = match$1.VAL;
        var match$3 = match$2.existingMatches;
        if (match$3 !== undefined) {
          var match$4 = match$2.newMatches;
          mtches = match$4 !== undefined ? ({
                TAG: "ExistingAndNew",
                new: Core__Array.keepSome(match$4.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    }),
                existing: Core__Array.keepSome(match$3.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    })
              }) : ({
                TAG: "Matches",
                _0: []
              });
        } else {
          mtches = {
            TAG: "Matches",
            _0: []
          };
        }
      } else if (variant$1 === "RentalPropertyRebroadcastedAMLEvent") {
        var match$5 = match$1.VAL;
        var match$6 = match$5.existingMatches;
        if (match$6 !== undefined) {
          var match$7 = match$5.newMatches;
          mtches = match$7 !== undefined ? ({
                TAG: "ExistingAndNew",
                new: Core__Array.keepSome(match$7.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    }),
                existing: Core__Array.keepSome(match$6.edges).map(function (e) {
                      return e.node.source.client.fragmentRefs;
                    })
              }) : ({
                TAG: "Matches",
                _0: []
              });
        } else {
          mtches = {
            TAG: "Matches",
            _0: []
          };
        }
      } else if (variant$1 === "RentalPropertyCreatedAMLEvent") {
        var match$8 = match$1.VAL.matches;
        mtches = match$8 !== undefined ? ({
              TAG: "Matches",
              _0: Core__Array.keepSome(match$8.edges).map(function (e) {
                    return e.node.source.client.fragmentRefs;
                  })
            }) : ({
              TAG: "Matches",
              _0: []
            });
      } else {
        mtches = {
          TAG: "Matches",
          _0: []
        };
      }
    } else {
      mtches = {
        TAG: "Matches",
        _0: []
      };
    }
  } else {
    mtches = {
      TAG: "Matches",
      _0: []
    };
  }
  var matchesText;
  var exit = 0;
  var client;
  var matches;
  if (mtches.TAG === "Matches") {
    var matches$1 = mtches._0;
    if (matches$1.length !== 1) {
      matches = matches$1;
      exit = 2;
    } else {
      var client$1 = matches$1[0];
      client = client$1;
      exit = 1;
    }
  } else {
    var matches$2 = mtches.new;
    var exit$1 = 0;
    var len = matches$2.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var match$9 = mtches.existing;
        if (match$9.length !== 1) {
          exit$1 = 3;
        } else {
          var client$2 = match$9[0];
          client = client$2;
          exit = 1;
        }
      }
    } else {
      var client$3 = matches$2[0];
      if (mtches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = client$3;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (mtches.existing.length !== 0) {
        if (matches$2.length !== 0) {
          var length = matches$2.length;
          matchesText = length.toString() + " more of your clients";
        } else {
          matches = mtches.existing;
          exit = 2;
        }
      } else {
        matches = matches$2;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        var clientName = Core__Option.getOr(getLabel$1(client), "client name");
        matchesText = "your buyer " + clientName;
        break;
    case 2 :
        var length$1 = matches.length;
        matchesText = length$1.toString() + " of your clients";
        break;
    
  }
  if (shouldShowPropertyDetails) {
    return agentText + " " + verb + " a rental property that matches " + matchesText;
  } else {
    return "A TAN agent " + verb + " a rental property that matches " + matchesText;
  }
}

var RentalPropertyMatchText = {
  AmlFragment: AmlFragment$1,
  make: NotificationWidgetTexts$RentalPropertyMatchText
};

var convertFragment$9 = NotificationWidgetTextsBuyerNeed_aml_graphql.Internal.convertFragment;

function use$9(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsBuyerNeed_aml_graphql.node, convertFragment$9, fRef);
}

function useOpt$9(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsBuyerNeed_aml_graphql.node, convertFragment$9);
}

var AmlFragment$2 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$9,
  use: use$9,
  useOpt: useOpt$9
};

function NotificationWidgetTexts$BuyerNeedMatchText(props) {
  var aml = RescriptRelay_Fragment.useFragment(NotificationWidgetTextsBuyerNeed_aml_graphql.node, convertFragment$9, props.aml);
  var agentText = useAgentTextOpt(Caml_option.some(aml.source.agent.fragmentRefs));
  var match = aml.event;
  var verb;
  if (match !== undefined) {
    var variant = match.NAME;
    verb = variant === "BuyerNeedEditedAMLEvent" || variant === "QuietBuyerNeedBroadcastedAMLEvent" ? "updated" : (
        variant === "UnselectedUnionMember" ? "-" : (
            agentText === "You" ? "have" : "has"
          )
      );
  } else {
    verb = agentText === "You" ? "have" : "has";
  }
  var match$1 = aml.event;
  var matches;
  if (match$1 !== undefined) {
    if (typeof match$1 === "object") {
      var variant$1 = match$1.NAME;
      if (variant$1 === "BroadcastBuyerNeedCreatedAMLEvent") {
        var match$2 = match$1.VAL.matches;
        matches = match$2 !== undefined ? ({
              TAG: "MatchesWithLocation",
              _0: Core__Array.keepSome(match$2.edges).map(function (e) {
                    return {
                            client: Core__Option.map(e.node.source.client, (function (v) {
                                    return v.fragmentRefs;
                                  })),
                            location: e.node.source.fragmentRefs
                          };
                  })
            }) : ({
              TAG: "MatchesWithLocation",
              _0: []
            });
      } else if (variant$1 === "QuietBuyerNeedBroadcastedAMLEvent") {
        var match$3 = match$1.VAL;
        var match$4 = match$3.existingMatches;
        if (match$4 !== undefined) {
          var match$5 = match$3.newMatches;
          matches = match$5 !== undefined ? ({
                TAG: "ExistingAndNewWithLocation",
                new: Core__Array.keepSome(match$5.edges).map(function (e) {
                      return {
                              client: Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    })),
                              location: e.node.source.fragmentRefs
                            };
                    }),
                existing: Core__Array.keepSome(match$4.edges).map(function (e) {
                      return {
                              client: Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    })),
                              location: e.node.source.fragmentRefs
                            };
                    })
              }) : ({
                TAG: "MatchesWithLocation",
                _0: []
              });
        } else {
          matches = {
            TAG: "MatchesWithLocation",
            _0: []
          };
        }
      } else if (variant$1 === "QuietBuyerNeedCreatedAMLEvent") {
        var match$6 = match$1.VAL.matches;
        matches = match$6 !== undefined ? ({
              TAG: "MatchesWithLocation",
              _0: Core__Array.keepSome(match$6.edges).map(function (e) {
                    return {
                            client: Core__Option.map(e.node.source.client, (function (v) {
                                    return v.fragmentRefs;
                                  })),
                            location: e.node.source.fragmentRefs
                          };
                  })
            }) : ({
              TAG: "MatchesWithLocation",
              _0: []
            });
      } else if (variant$1 === "BuyerNeedEditedAMLEvent") {
        var match$7 = match$1.VAL;
        var match$8 = match$7.existingMatches;
        if (match$8 !== undefined) {
          var match$9 = match$7.newMatches;
          matches = match$9 !== undefined ? ({
                TAG: "ExistingAndNewWithLocation",
                new: Core__Array.keepSome(match$9.edges).map(function (e) {
                      return {
                              client: Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    })),
                              location: e.node.source.fragmentRefs
                            };
                    }),
                existing: Core__Array.keepSome(match$8.edges).map(function (e) {
                      return {
                              client: Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    })),
                              location: e.node.source.fragmentRefs
                            };
                    })
              }) : ({
                TAG: "MatchesWithLocation",
                _0: []
              });
        } else {
          matches = {
            TAG: "MatchesWithLocation",
            _0: []
          };
        }
      } else {
        matches = {
          TAG: "MatchesWithLocation",
          _0: []
        };
      }
    } else {
      matches = {
        TAG: "MatchesWithLocation",
        _0: []
      };
    }
  } else {
    matches = {
      TAG: "MatchesWithLocation",
      _0: []
    };
  }
  var matchesText;
  var exit = 0;
  var client;
  var $$location;
  var matches$1;
  if (matches.TAG === "MatchesWithLocation") {
    var matches$2 = matches._0;
    if (matches$2.length !== 1) {
      matches$1 = matches$2;
      exit = 2;
    } else {
      var match$10 = matches$2[0];
      client = match$10.client;
      $$location = match$10.location;
      exit = 1;
    }
  } else {
    var matches$3 = matches.new;
    var exit$1 = 0;
    var len = matches$3.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var matches$4 = matches.existing;
        if (matches$4.length !== 1) {
          matches$1 = matches$4;
          exit = 2;
        } else {
          var match$11 = matches$4[0];
          client = match$11.client;
          $$location = match$11.location;
          exit = 1;
        }
      }
    } else {
      var match$12 = matches$3[0];
      if (matches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = match$12.client;
        $$location = match$12.location;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (matches.existing.length !== 0) {
        matchesText = matches$3.length.toString() + " more of your sellers";
      } else {
        matches$1 = matches$3;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        matchesText = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(client, getLabel$1), (function (name) {
                    return name + "'s property";
                  })), getLabel($$location));
        break;
    case 2 :
        matchesText = matches$1.length.toString() + " of your sellers";
        break;
    
  }
  return agentText + " " + verb + " a buyer that matches " + matchesText;
}

var BuyerNeedMatchText = {
  AmlFragment: AmlFragment$2,
  make: NotificationWidgetTexts$BuyerNeedMatchText
};

var convertFragment$10 = NotificationWidgetTextsRentalNeed_aml_graphql.Internal.convertFragment;

function use$10(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetTextsRentalNeed_aml_graphql.node, convertFragment$10, fRef);
}

function useOpt$10(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetTextsRentalNeed_aml_graphql.node, convertFragment$10);
}

var AmlFragment$3 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$10,
  use: use$10,
  useOpt: useOpt$10
};

function NotificationWidgetTexts$RentalNeedMatchText(props) {
  var aml = RescriptRelay_Fragment.useFragment(NotificationWidgetTextsRentalNeed_aml_graphql.node, convertFragment$10, props.aml);
  var agentText = useAgentTextOpt(Caml_option.some(aml.source.agent.fragmentRefs));
  var match = aml.event;
  var verb;
  if (match !== undefined) {
    var variant = match.NAME;
    verb = variant === "RentalNeedCreatedAMLEvent" ? (
        agentText === "You" ? "have" : "has"
      ) : (
        variant === "UnselectedUnionMember" ? "-" : "updated"
      );
  } else {
    verb = agentText === "You" ? "have" : "has";
  }
  var match$1 = aml.event;
  var matches;
  if (match$1 !== undefined) {
    if (typeof match$1 === "object") {
      var variant$1 = match$1.NAME;
      if (variant$1 === "RentalNeedEditedAMLEvent") {
        var match$2 = match$1.VAL;
        var match$3 = match$2.existingMatches;
        if (match$3 !== undefined) {
          var match$4 = match$2.newMatches;
          matches = match$4 !== undefined ? ({
                TAG: "ExistingAndNewWithLocation",
                new: Core__Array.keepSome(match$4.edges).map(function (e) {
                      return {
                              client: Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    })),
                              location: e.node.source.fragmentRefs
                            };
                    }),
                existing: Core__Array.keepSome(match$3.edges).map(function (e) {
                      return {
                              client: Core__Option.map(e.node.source.client, (function (v) {
                                      return v.fragmentRefs;
                                    })),
                              location: e.node.source.fragmentRefs
                            };
                    })
              }) : ({
                TAG: "MatchesWithLocation",
                _0: []
              });
        } else {
          matches = {
            TAG: "MatchesWithLocation",
            _0: []
          };
        }
      } else if (variant$1 === "RentalNeedCreatedAMLEvent") {
        var match$5 = match$1.VAL.matches;
        matches = match$5 !== undefined ? ({
              TAG: "MatchesWithLocation",
              _0: Core__Array.keepSome(match$5.edges).map(function (e) {
                    return {
                            client: Core__Option.map(e.node.source.client, (function (v) {
                                    return v.fragmentRefs;
                                  })),
                            location: e.node.source.fragmentRefs
                          };
                  })
            }) : ({
              TAG: "MatchesWithLocation",
              _0: []
            });
      } else {
        matches = {
          TAG: "MatchesWithLocation",
          _0: []
        };
      }
    } else {
      matches = {
        TAG: "MatchesWithLocation",
        _0: []
      };
    }
  } else {
    matches = {
      TAG: "MatchesWithLocation",
      _0: []
    };
  }
  var matchesText;
  var exit = 0;
  var client;
  var $$location;
  var matches$1;
  if (matches.TAG === "MatchesWithLocation") {
    var matches$2 = matches._0;
    if (matches$2.length !== 1) {
      matches$1 = matches$2;
      exit = 2;
    } else {
      var match$6 = matches$2[0];
      client = match$6.client;
      $$location = match$6.location;
      exit = 1;
    }
  } else {
    var matches$3 = matches.new;
    var exit$1 = 0;
    var len = matches$3.length;
    if (len !== 1) {
      if (len !== 0) {
        exit$1 = 3;
      } else {
        var match$7 = matches.existing;
        if (match$7.length !== 1) {
          exit$1 = 3;
        } else {
          var match$8 = match$7[0];
          client = match$8.client;
          $$location = match$8.location;
          exit = 1;
        }
      }
    } else {
      var match$9 = matches$3[0];
      if (matches.existing.length !== 0) {
        exit$1 = 3;
      } else {
        client = match$9.client;
        $$location = match$9.location;
        exit = 1;
      }
    }
    if (exit$1 === 3) {
      if (matches.existing.length !== 0) {
        if (matches$3.length !== 0) {
          matchesText = matches$3.length.toString() + " more of your clients";
        } else {
          matches$1 = matches.existing;
          exit = 2;
        }
      } else {
        matches$1 = matches$3;
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        matchesText = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(client, getLabel$1), (function (name) {
                    return name + "'s property";
                  })), getLabel($$location));
        break;
    case 2 :
        matchesText = matches$1.length.toString() + " of your clients";
        break;
    
  }
  return agentText + " " + verb + " a client that matches " + matchesText;
}

var RentalNeedMatchText = {
  AmlFragment: AmlFragment$3,
  make: NotificationWidgetTexts$RentalNeedMatchText
};

exports.PropertyLocationLabel = PropertyLocationLabel;
exports.ClientLabel = ClientLabel;
exports.AgentText = AgentText;
exports.AgentId = AgentId;
exports.ShowingNotification = ShowingNotification;
exports.PropertyMatchText = PropertyMatchText;
exports.RentalPropertyMatchText = RentalPropertyMatchText;
exports.BuyerNeedMatchText = BuyerNeedMatchText;
exports.RentalNeedMatchText = RentalNeedMatchText;
/* Tooltip Not a pure module */
