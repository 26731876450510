import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from 'next/link'

import { Span } from '../View'
import { Icon } from 'uikit'
import { mediaBreakpointUp } from '../../styles/media'

const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.alto};
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: ${props => props.theme.colors.stTropaz};
  text-transform: uppercase;
  text-decoration: none;
  transition: 150ms;
  padding: 5px 15px;
  margin: 0 15px;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${props => props.theme.colors.stTropaz};
    border-color: ${props => props.theme.colors.stTropaz};
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }
  ${mediaBreakpointUp('md')`
    margin: 0;
  `};
`
const ReadMoreButton = ({ href, children }) => (
  <Button href={href} passHref>
    <Span mr={5}>{children || 'read more'}</Span>
    <Icon icon="arrow-right" fontSize="14px" />
  </Button>
)

ReadMoreButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string,
}

export default ReadMoreButton
