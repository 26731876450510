// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Responsive = require("../../../uikit/reason/helpers/Responsive.bs.js");
var Text__Jsx3 = require("../../../uikit/reason/helpers/Text__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");
var MarkupHelpers = require("components/MarkupHelpers");

function ErrorComponent(props) {
  return JsxRuntime.jsx(MarkupHelpers.ContentBox, {
              paddingFull: true,
              hasShadow: true,
              children: Caml_option.some(props.message)
            });
}

function ErrorComponent$Large(props) {
  return JsxRuntime.jsx(Text__Jsx3.make, {
              as_: "div",
              fontSize: Responsive.make({
                    NAME: "px",
                    VAL: 14
                  }, {
                    NAME: "px",
                    VAL: 16
                  }, {
                    NAME: "px",
                    VAL: 18
                  }, undefined, undefined, undefined, undefined),
              lineHeight: {
                NAME: "float",
                VAL: 1.5
              },
              textAlign: "center",
              children: props.children
            });
}

var Large = {
  $$Text: undefined,
  responsive: Responsive.make,
  make: ErrorComponent$Large
};

var make = ErrorComponent;

exports.make = make;
exports.Large = Large;
/* Text__Jsx3 Not a pure module */
