// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function calculateRatio(param) {
  var _window = typeof window === "undefined" ? undefined : window;
  if (_window !== undefined) {
    return Math.round(devicePixelRatio);
  } else {
    return 1.0;
  }
}

var get = calculateRatio;

exports.calculateRatio = calculateRatio;
exports.get = get;
/* No side effect */
