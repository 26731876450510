import React, { cloneElement } from 'react'
import { useTooltip, TooltipPopup } from '@reach/tooltip'
import { useTransition, animated } from 'react-spring'

import '@reach/tooltip/styles.css'

const AnimatedTooltipContent = animated(TooltipPopup)
const interpolateScale = v => `scale(${v / 100})`
export function AnimatedTooltip({ children, ...etc }) {
  const [trigger, tooltip, isVisible] = useTooltip()
  const transitions = useTransition(isVisible ? tooltip : false, null, {
    from: { opacity: 0, transform: 95 },
    enter: { opacity: 1, transform: 100 },
    leave: { opacity: 0, transform: 95 },
    config: {
      tension: 2000,
      friction: 100,
      precision: 0.1,
    },
  })
  return (
    <>
      {cloneElement(children, trigger)}
      {transitions.map(
        ({ item: tooltipItem, props: styles, key }) =>
          tooltipItem && (
            <AnimatedTooltipContent
              key={key}
              {...tooltipItem}
              {...etc}
              style={{ ...styles, transform: styles.transform.interpolate(interpolateScale) }}
            />
          )
      )}
    </>
  )
}
