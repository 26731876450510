// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Scalars = require("../scalars/Scalars.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_fragment_property_propertyLocation(u) {
  var v = u.__typename;
  switch (v) {
    case "PropertyLocationWithHiddenAddress" :
        return {
                NAME: "PropertyLocationWithHiddenAddress",
                VAL: u
              };
    case "PropertyLocationWithVisibleAddress" :
        return {
                NAME: "PropertyLocationWithVisibleAddress",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment_property_propertyLocation(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"startDateV2":{"c":"Scalars.PlainDateTime"},"property_propertyLocation":{"u":"fragment_property_propertyLocation"},"property_agentV2":{"f":""},"property":{"f":""},"endDateV2":{"c":"Scalars.PlainDateTime"}}};

var fragmentConverterMap = {
  "Scalars.PlainDateTime": Scalars.PlainDateTime.parse,
  fragment_property_propertyLocation: unwrap_fragment_property_propertyLocation
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function visibilityReason_decode($$enum) {
  if ($$enum === "VISIBLE_TO_ALL" || $$enum === "VISIBLE_TO_TAN_STAFF" || $$enum === "VISIBLE_TO_ACCOUNT_MEMBER") {
    return $$enum;
  }
  
}

var visibilityReason_fromString = visibilityReason_decode;

var Utils = {
  visibilityReason_decode: visibilityReason_decode,
  visibilityReason_fromString: visibilityReason_fromString
};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationDisplay",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v3 = {
  "kind": "RequiredField",
  "field": (v1/*: any*/),
  "action": "NONE",
  "path": "property.propertyLocation.locationDisplay"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationWidgetTexts_showing",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDateV2",
        "storageKey": null
      },
      "action": "THROW",
      "path": "startDateV2"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDateV2",
        "storageKey": null
      },
      "action": "THROW",
      "path": "endDateV2"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "ForSalePropertyV5",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "kind": "InlineDataFragmentSpread",
            "name": "NotificationWidgetTextsPropertyLocationLabel_property",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "propertyLocation",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "type": "PropertyLocationWithHiddenAddress",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "type": "PropertyLocationWithVisibleAddress",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "PropertyV5",
                "abstractKey": "__isPropertyV5"
              }
            ],
            "args": null,
            "argumentDefinitions": []
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "propertyLocation",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "PropertyLocationWithHiddenAddress",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "visibilityRule",
                    "storageKey": null
                  }
                ],
                "type": "PropertyLocationWithVisibleAddress",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "agentV2",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotificationWidgetTextsForSale_agent"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotificationWidgetTextsForSale_agentId"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "property.agentV2"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "property"
    }
  ],
  "type": "ShowingV5",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.unwrap_fragment_property_propertyLocation = unwrap_fragment_property_propertyLocation;
exports.wrap_fragment_property_propertyLocation = wrap_fragment_property_propertyLocation;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
