// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CssJs = require("bs-css-emotion/src/CssJs.bs.js");
var Sentry = require("../../../reason/Sentry.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../../reason/common/Theme/Theme__Color__Vars.bs.js");
var ReactErrorBoundary = require("react-error-boundary");

var error = CssJs.style([
      CssJs.color(Theme__Color__Vars.crimson),
      CssJs.fontWeight({
            NAME: "num",
            VAL: 500
          })
    ]);

var Styles = {
  error: error
};

function FragmentErrorBoundary$ErrorState(props) {
  return JsxRuntime.jsx("div", {
              children: "⚠ Error retrieving " + props.fragmentDisplayName,
              className: error
            });
}

var ErrorState = {
  Styles: Styles,
  make: FragmentErrorBoundary$ErrorState
};

function FragmentErrorBoundary(props) {
  var fragmentDisplayName = props.fragmentDisplayName;
  return JsxRuntime.jsx(ReactErrorBoundary.ErrorBoundary, {
              FallbackComponent: (function (_error) {
                  return JsxRuntime.jsx(FragmentErrorBoundary$ErrorState, {
                              fragmentDisplayName: fragmentDisplayName
                            });
                }),
              children: props.children,
              onError: (function (error) {
                  Sentry.captureException(error, "FragmentErrorBoundary.res", undefined, undefined, undefined);
                })
            });
}

var make = FragmentErrorBoundary;

exports.ErrorState = ErrorState;
exports.make = make;
/* error Not a pure module */
