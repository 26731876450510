// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_response_markNotificationReadByCurrentUserV5(u) {
  var v = u.__typename;
  if (v === "NotificationMarkedAsReadByCurrentUser") {
    return {
            NAME: "NotificationMarkedAsReadByCurrentUser",
            VAL: u
          };
  } else {
    return {
            NAME: "UnselectedUnionMember",
            VAL: v
          };
  }
}

function wrap_response_markNotificationReadByCurrentUserV5(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var variablesConverter = {"markNotificationAsReadByCurrentUserInput":{},"__root":{"input":{"r":"markNotificationAsReadByCurrentUserInput"}}};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"markNotificationReadByCurrentUserV5":{"u":"response_markNotificationReadByCurrentUserV5"}}};

var wrapResponseConverterMap = {
  response_markNotificationReadByCurrentUserV5: wrap_response_markNotificationReadByCurrentUserV5
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"markNotificationReadByCurrentUserV5":{"u":"response_markNotificationReadByCurrentUserV5"}}};

var responseConverterMap = {
  response_markNotificationReadByCurrentUserV5: unwrap_response_markNotificationReadByCurrentUserV5
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function notificationStatus_decode($$enum) {
  if ($$enum === "READ" || $$enum === "UNREAD") {
    return $$enum;
  }
  
}

var notificationStatus_fromString = notificationStatus_decode;

var Utils = {
  notificationStatus_decode: notificationStatus_decode,
  notificationStatus_fromString: notificationStatus_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "markNotificationReadByCurrentUserV5",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "notification",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "NotificationMarkedAsReadByCurrentUser",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "markNotificationReadByCurrentUserV5",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "notification",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "NotificationMarkedAsReadByCurrentUser",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a22fe28f947fa85226bf10ba59f4466e",
    "id": null,
    "metadata": {},
    "name": "NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation(\n  $input: MarkNotificationAsReadByCurrentUserInput!\n) {\n  markNotificationReadByCurrentUserV5(input: $input) {\n    __typename\n    ... on NotificationMarkedAsReadByCurrentUser {\n      notification {\n        __typename\n        status\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.unwrap_response_markNotificationReadByCurrentUserV5 = unwrap_response_markNotificationReadByCurrentUserV5;
exports.wrap_response_markNotificationReadByCurrentUserV5 = wrap_response_markNotificationReadByCurrentUserV5;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
