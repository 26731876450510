// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

function unwrap_fragment_propertyLocation(u) {
  var v = u.__typename;
  switch (v) {
    case "PropertyLocationWithHiddenAddress" :
        return {
                NAME: "PropertyLocationWithHiddenAddress",
                VAL: u
              };
    case "PropertyLocationWithVisibleAddress" :
        return {
                NAME: "PropertyLocationWithVisibleAddress",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment_propertyLocation(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"propertyLocation":{"u":"fragment_propertyLocation"}}};

var fragmentConverterMap = {
  fragment_propertyLocation: unwrap_fragment_propertyLocation
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "kind": "InlineDataFragment",
  "name": "NotificationWidgetTextsPropertyLocationLabel_property"
};

exports.Types = Types;
exports.unwrap_fragment_propertyLocation = unwrap_fragment_propertyLocation;
exports.wrap_fragment_propertyLocation = wrap_fragment_propertyLocation;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
