import React from 'react'
import styled from 'styled-components'
import { Icon } from 'uikit'
import { mediaBreakpointUp } from '../../../styles/media'

const StyledNotificationTypeIcon = styled.div`
  display: block;
  flex-shrink: 0;
  width: 40px;
  position: relative;
  svg {
    max-width: calc(100% - 15px);
    max-height: calc(100% - 15px);
    margin-left: 2px; /* Visual adjustment */
  }
  ${mediaBreakpointUp('sm')`
    width: 50px;
  `};
`

const StyledNotificationTypeIconOuter = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
`

const StyledNotificationTypeIconInner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.alto};
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 30px;
  color: ${props => props.theme.colors.stTropaz};
`

const NotificationTypeIcon = ({ type }) => (
  <StyledNotificationTypeIcon>
    <StyledNotificationTypeIconOuter>
      <StyledNotificationTypeIconInner>
        <Icon icon={type} />
      </StyledNotificationTypeIconInner>
    </StyledNotificationTypeIconOuter>
  </StyledNotificationTypeIcon>
)

export const NotificationTypeIconContainer = ({ children }) => (
  <StyledNotificationTypeIcon>
    <StyledNotificationTypeIconOuter>
      <StyledNotificationTypeIconInner>{children}</StyledNotificationTypeIconInner>
    </StyledNotificationTypeIconOuter>
  </StyledNotificationTypeIcon>
)

export default NotificationTypeIcon
