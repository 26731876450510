// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var UTCDate = require("../reason/common/types/UTCDate.bs.js");
var TemporalPolyfill = require("temporal-polyfill");

function fromFloat(v) {
  return v;
}

function fromInt(prim) {
  return prim;
}

var PriceInput = {
  fromFloat: fromFloat,
  fromInt: fromInt
};

function fromJsDate(prim) {
  return prim.toISOString();
}

var DateTime = {
  toUTC: UTCDate.fromString,
  fromJsDate: fromJsDate
};

var emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

function validate(v) {
  if (emailRegex.test(v)) {
    return {
            TAG: "Ok",
            _0: v
          };
  } else {
    return {
            TAG: "Error",
            _0: "InvalidEmailAddress"
          };
  }
}

function toString(v) {
  return v;
}

var EmailAddress = {
  validate: validate,
  toString: toString
};

var JSONObject = {};

function toWebapiUrl(t) {
  return new URL(t);
}

function toString$1(v) {
  return v;
}

function fromString(v) {
  var urlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  if (urlRegex.test(v)) {
    return {
            TAG: "Ok",
            _0: v
          };
  } else {
    return {
            TAG: "Error",
            _0: "InvalidURL"
          };
  }
}

var HTML = {};

function parse(prim) {
  return TemporalPolyfill.Temporal.PlainDateTime.from(prim);
}

function serialize(prim) {
  return prim.toString();
}

var PlainDateTime = {
  parse: parse,
  serialize: serialize
};

var CalendarDate;

var $$URL$1 = {
  toString: toString$1,
  toWebapiUrl: toWebapiUrl,
  fromString: fromString
};

exports.PriceInput = PriceInput;
exports.CalendarDate = CalendarDate;
exports.DateTime = DateTime;
exports.EmailAddress = EmailAddress;
exports.JSONObject = JSONObject;
exports.$$URL = $$URL$1;
exports.HTML = HTML;
exports.PlainDateTime = PlainDateTime;
/* UTCDate Not a pure module */
